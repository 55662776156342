<template lang="pug">
    router-link.btn.start.white--text.text-none(:to="`/sign-up?lang=${lang}`" :class="{block: $vuetify.breakpoint.smAndDown}") {{text.buttonText}}
        v-icon(color="white").ml-2 mdi-arrow-right
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'BtnGetStarted',
  data() {
    return {
      textVersions: {
        en: {
          buttonText: 'Get started for free'
        },
        ko: {
          buttonText: '무료로 시작하기'
        },
        ru: {
          buttonText: 'Начать бесплатно'
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>

  .btn {
    background: black;
    display: inline-block;
    padding: 0.5rem;
    font-size: 1.15rem;
    box-shadow: 3px 3px rgba(0, 0, 0, 0);
     text-align:center;
  }

  .btn:hover {
    transition: 0.3s all ease-in;
    box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  }

  .btn.block{width:100%;}

@media only screen and (min-width: 600px) {

  .btn {
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.35rem;
  }

}

</style>
