<template lang="pug">
    .pricing#pricing
        h2.text-center.mb-5 {{text.title}}
        v-row(align="center")
            v-col(cols="12" md="6")
                p(v-html="text.subtitle")
            v-col(cols="12" md="6")
                PricingCreditTable

        v-row.mt-12
            v-col(cols="12" md="3", v-for="priceBlock in text.priceBlocks", :key="priceBlock.name").mb-6.mb-md-0
                PriceBlock(:name="priceBlock.name", :amount="priceBlock.amount", :priceInDollars="priceBlock.priceInDollars", :subheader="priceBlock.subheader", :savings="priceBlock.savings")
            //- v-col(cols="12" md="3").mb-6.mb-md-0
            //-     PriceBlock(name="Mini Pack", amount="20 Credits", priceInDollars="4.50", subheader="20 credits x $4.50 = <b>$90</b>", savings="Save 10%")
            //- v-col(cols="12" md="3").mb-6.mb-md-0
            //-     PriceBlock(name="Pro Pack", amount="50 Credits", priceInDollars="3.50", subheader="50 credits x $3.50 = <b>$175</b>", savings="Save 30%")
            //- v-col(cols="12" md="3").mb-6.mb-md-0
            //-     PriceBlock(name="Pay As You Go", amount="Unlimited Credits", priceInDollars="5", subheader="Buy a credit, use a credit")
</template>

<script>
import { get } from 'vuex-pathify'
import PriceBlock from '@/components/Static/PriceBlock'
import PricingCreditTable from '@/components/Static/PricingCreditTable'
import PricingExample from '@/components/Static/PricingExample'
export default {
  name: 'Pricing',
  components: { PriceBlock, PricingCreditTable, PricingExample },
  data() {
    return {
      textVersions: {
        en: {
          title: 'Pricing',
          subtitle:
            'You can <b>Pay As You Go</b> or purchase a <b>credit pack</b>, so that your whole team can benefit from clear, professional English writing.',
          priceBlocks: [
            { name: 'Trial', amount: '5 Credits', priceInDollars: '0', subheader: 'no credit card required' },
            { name: 'Pay As You Go', amount: 'Unlimited Credits', priceInDollars: '5', subheader: 'Buy a credit, use a credit' },
            { name: 'Micro Pack', amount: '10 Credits', priceInDollars: '4.70', subheader: '10 credits x $4.70 = <b>$47</b>', savings: 'Save 6%' },
            // { name: 'Mini Pack', amount: '20 Credits', priceInDollars: '4.50', subheader: '20 credits x $4.50 = <b>$90</b>', savings: 'Save 10%' },
            { name: 'Pro Pack', amount: '50 Credits', priceInDollars: '3.50', subheader: '50 credits x $3.50 = <b>$175</b>', savings: 'Save 30%' }
          ]
        },
        ru: {
          title: 'Цены',
          subtitle:
            'Вы можете <b>платить по мере использования</b> или приобрести <b>тарифный план</b>, чтобы Ваша команда могла получить пользу от грамотной английской переписки.',
          priceBlocks: [
            { name: 'Пробный Тариф', amount: '5 кредитов', priceInDollars: '0', subheader: 'Кредитная карта не требуется' },
            { name: 'Плати по факту', amount: 'Неограниченные кредиты', priceInDollars: '5', subheader: 'Купите кредит - используйте кредит' },
            { name: 'Тариф Micro', amount: '10 кредитов', priceInDollars: '4.70', subheader: '10 кредитов x $4.70 = <b>$47</b>', savings: 'Сэкономьте 6%' },
            { name: 'Тариф Pro', amount: '50 кредитов', priceInDollars: '3.50', subheader: '50 кредитов x $3.50 = <b>$175</b>', savings: 'Сэкономьте 30%' }
          ]
        },
        ko: {
          title: '가격',
          subtitle:
            '사용료를 선지급하거나 신용 패키지를 구입하시면, 고객님의 팀 전체가 명확하고 전문적인 영작문 서비스 혜택을 받을 수 있습니다.',
          priceBlocks: [
            { name: '체험판', amount: '5 크레딧', priceInDollars: '0', subheader: '신용 카드 필요 없음' },
            { name: '사용료 선지급', amount: '무제한 크레딧', priceInDollars: '5', subheader: '크레딧 구매, 크레딧 사용' },
            { name: '마이크로 패키지', amount: '10 크레딧', priceInDollars: '4.70', subheader: '10 크레딧 x $4.70 = <b>$47</b>', savings: '6% 적립' },
            { name: '프로 패키지', amount: '50 크레딧', priceInDollars: '3.50', subheader: '50 크레딧 x $3.50 = <b>$175</b>', savings: '30% 적립' }
          ]
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>
.pricing {
  .wrapper {
    max-width: 95%;
    margin: auto;
  }
  strong.num {
    white-space: nowrap;
  }
  .pack-title {
    margin-bottom: 1rem;
  }
  ul.description li {
    text-align: left;
    font-size: 1rem;
  }

  .price {
    font-style: oblique;
    margin-bottom: 2rem;
  }

  .pro-bg {
    background: rgb(245, 255, 247);
  }
  @media only screen and (min-width: 600px) {
    .wrapper {
      max-width: 60%;
    }
  }
}
</style>
