<template lang="pug">
    .pricing-credit-table.pa-1.pa-md-5
        v-simple-table(dense)
            template(v-slot:default)
                thead
                    tr
                        th.text-center.py-2
                            v-img.placeholder(src="iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAAOUlEQVR42u3OIQEAAAACIP1/2hkWWEBzVgEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAYF3YDicAEE8VTiYAAAAAElFTkSuQmCC" width="64px" height="64px").mx-auto.mb-3
                            span.small {{text.headers[0]}}
                        th.text-center
                            v-img(src="@/assets/24_hours.svg" width="64px" height="64px").mx-auto.mb-3
                            span.small {{text.headers[2]}}
                        th.text-center
                            v-img(src="@/assets/1_hour.svg" width="64px" height="64px").mx-auto.mb-3
                            span.small {{text.headers[2]}}
                tbody
                    tr.text-left
                        td.text-left.text-md-center {{numWords(200)}}
                        td.text-center {{numCredits(1)}}
                        td.text-center {{numCredits(2)}}
                    tr.text-left
                        td.text-left.text-md-center {{numWords(500)}}
                        td.text-center {{numCredits(2)}}
                        td.text-center {{numCredits(4)}}
                    tr.text-left
                        td.text-left.text-md-center {{numWords(1000)}}
                        td.text-center {{numCredits(4)}}
                        td.text-center {{numCredits(8)}}
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'PricingCreditTable',
  data() {
    return {
      textVersions: {
        en: {
          headers: ['Length', 'Cost', 'Cost'],
          subheaders: ['', '24-hour service', '1-hour service']
        },
        ru: {
          headers: ['Длина', 'Стоимость', 'Стоимость'],
          subheaders: [
            '',
            '24-часовое обслуживание',
            '60-минутное обслуживание'
          ]
        },
        ko: {
          headers: ['글자 수', '비용', '비용'],
          subheaders: ['', '24시간 서비스', '1시간 서비스']
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  },
  methods: {
    numWords(num) {
      if (!this.lang) return num + ' words'

      if (this.lang === 'en') return num + ' words'
      else if (this.lang === 'ko') return num + '자'
      else if (this.lang === 'ru') return num + ' слов'
      else return num + ' words'
    },
    numCredits(num) {
      const creditOrCreditsEn = num === 1 ? 'credit' : 'credits'

      const creditOrCreditsRu = num => {
        if (num === 1) return 'кредит'
        if (num < 5) return 'кредита'
        return 'кредитов'
      }

      if (!this.lang) return num + ' ' + creditOrCreditsEn

      if (this.lang === 'ko') return num + ' ' + '크레딧'
      else if (this.lang === 'ru') return num + ' ' + creditOrCreditsRu(num)
      else return num + ' ' + creditOrCreditsEn
    }
  }
}
</script>

<style lang="scss" scoped>
$green: #8ae687;
.pricing-credit-table {
  border: 2px solid $green;
  border-radius: 5px;
  // box-shadow: 10px 10px 25px 3px rgba(0, 0, 0, 0.05);
  span.small {
    font-size: 0.9rem;
  }
}

th .time {
  font-size: inherit;
  line-height: 1;
}

@media only screen and (min-width: 600px) {
  .pricing-credit-table {
    span.small {
      font-size: initial;
    }
  }
}
</style>
