<template lang="pug">
    .feature(ref="feature")
        v-row(align="center" :class="{'flex-md-row-reverse': alt && !wide}" dense).mb-6.flex-md-row.flex-column-reverse
              v-col(cols="12", :md="wide ? 8 : 7")
                .label.text-center.text-md-left {{label}}
                h2.mb-3 {{title}}
                .content(v-intersect.once="{handler: onIntersect, options: {threshold: [1]}}")
                  p(v-for="paragraph in paragraphs", :key="paragraph", v-html="paragraph")
              v-col(cols="12", :md="wide ? 4 : 5")
                v-img(:src="requireAsset(image)" min-width="200px" :min-height="$vuetify.breakpoint.mdAndUp ? '400px' : '100px'" contain)
</template>

<script>
import { get } from 'vuex-pathify'
import { annotate } from 'rough-notation'
export default {
  name: 'Feature',
  props: {
    label: { type: String, required: true },
    title: { type: String, required: true },
    paragraphs: { type: Array, default: null },
    image: { type: String, default: null },
    alt: { type: Boolean, default: false },
    wide: { type: Boolean, default: false }
  },
  mounted() {

  },
  computed: {
    ...get({ requireAsset: 'requireAsset' })
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) { this.doAnimation() }
    },
    doAnimation() {
      document.fonts.ready.then(() => {
        const ref = this.$refs.feature
        if (ref) {
          const e = ref.querySelector('.highlight')
          if (e) {
            const annotation = annotate(e, { type: 'highlight', multiline: true })
            setTimeout(() => {
              e.style.color = 'white'
            }, 200)

            annotation.show()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .feature .label {
    color: rgba(0, 0, 0, 0.63);
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
    font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
  }

  ::v-deep .highlight{
    transition:0.2s color ease-in;
  }

  @media only screen and (min-width: 600px) {
::v-deep .highlight{
    transition:0.2s color ease-in;
    white-space: nowrap;
  }
  }
</style>
