<template lang="pug">
    .masthead(:class="{'lang-ru': lang && lang === 'ru'}")
        v-row(align="start")
            v-col(cols="12" md="5")
                v-img(eager lazy-src="@/assets/sending_emails_flatline.jpg" src="@/assets/sending_emails_flatline.svg" min-width="100px" :min-height="$vuetify.breakpoint.mdAndUp? '400px' : '50px'" max-width="800px" contain style="width:100%; height:auto;" @load="handleImageLoaded").mr-6
            v-col(cols="12" md="7")
                h1.mb-5 {{text.title}}
                .content
                  span.subtitle(v-html="text.subtitle")
                BtnGetStarted.mt-6

</template>

<script>
import { get } from 'vuex-pathify'
import { annotate } from 'rough-notation'
import BtnGetStarted from '@/components/Static/BtnGetStarted'
export default {
  name: 'Masthead',
  components: { BtnGetStarted },
  watch: {
    lang() {
      setTimeout(() => {
        this.doAnnotation()
      }, 2000)
    }
  },
  data() {
    return {
      textVersions: {
        en: {
          title:
            'Get an in-house native English editor, without paying a monthly salary',
          subtitle:
            "PROIT English is a <span class='anno anno1'>human-powered proofreading service</span> for companies who sell to international markets."
        },
        ko: {
          title: '고정된 월급없이 사내 원어민 에디터 확보',
          subtitle:
            "PROIT English는 국제적인 사업을 하는 회사들을 위해 <span class='anno anno1'>원어민분들이 직접 교정하는 서비스입니다.</span>"
        },
        ru: {
          title:
            'Возьмите в штат редактора-носителя английского языка, без ежемесячной оплаты.',
          subtitle:
            'PROIT English – это сервис, осуществляющий <span class=\'anno anno1\'>редактирование текста вручную</span> для компаний, ориентированных на международный рынок.'
        }
      },

      ready: false,
      didAnnotation: false
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      // for small devices, we don't ever load the masthead image, so instead on mounted we'll start annotations
      // for large devices we skip this, and run the same code after image loaded.
      document.fonts.ready.then(() => {
        setTimeout(() => {
          this.doAnnotation()
        }, 3000)
      })
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    showImage() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  },
  methods: {
    handleImageLoaded() {
      document.fonts.ready.then(() => {
        setTimeout(() => {
          this.doAnnotation()
        }, 2500)
      })
    },
    doAnnotation() {
      const e = document.querySelector('.anno1')
      if (e) {
        const annotation = annotate(e, {
          type: 'underline',
          multiline: true,
          color: this.$vuetify.theme.themes.light.primary
        })
        annotation.show()
      }
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  font-weight: bolder;
  line-height: 1.35;
  font-size: 1.8rem;
  color: black;
}

h2 {
  font-size: 2rem;
}

span.subtitle {
  color: rgb(151, 143, 143);
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 3rem;
  }
}
</style>
