<template lang="pug">
    .final-cta
        v-row
            v-col(cols="12" md="6")
                v-img(src="@/assets/career__flatline.svg" min-width="300px"  :min-height="$vuetify.breakpoint.mdAndUp ? '400px' : '100px'" contain)
            v-col(cols="12" md="6")
                h2.mb-6(v-html="text.title")
                p {{text.subtitle}}
                BtnGetStarted.mt-6
</template>

<script>
import BtnGetStarted from '@/components/Static/BtnGetStarted'
import { get } from 'vuex-pathify'
export default {
  name: 'FinalCta',
  components: { BtnGetStarted },
  data() {
    return {
      textVersions: {
        en: {
          title: 'Do companies who communicate in perfect, native English really get more customers?',
          subtitle: 'You can start with our free demo tier to find out.'
        },
        ru: {
          title: 'Действительно ли компании, которые общаются на идеальном английском языке, получают больше клиентов?',
          subtitle: 'Вы можете начать с нашей бесплатной демо-версии, чтобы узнать.'
        },
        ko: {
          title: '완벽한 원어민 영어로 의사소통을 하는 회사들은 정말 더 많은 고객을 확보할수 있을까?',
          subtitle: '무료 데모 티어부터 확인해 보십시오.'
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
