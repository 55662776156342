<template lang="pug">
    .cta-wide.my-12
        v-container.max-width
            v-row(dense align="center" justify="center")
                v-col(cols="12" md="2")
                    v-img(src="@/assets/rocket_boy.svg" min-width="300px", max-width="450px", aspect-ratio="1" contain).mx-auto
                v-col(cols="12" md="6" offset-md="1")
                    .px-6.px-md-0
                        .label.white--text.mb-0 {{label}}
                        h2.white--text.mb-6 {{title}}
                        v-btn(color="white" outlined :block="$vuetify.breakpoint.smAndDown", large :to="`/sign-up?lang=${lang}`") {{button.text}}
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'CtaWide',
  props: {
    label: { type: String, required: true },
    title: { type: String, required: true },
    button: { type: Object, default: null }
  },
  computed: {
    ...get({ lang: 'user/lang' })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.cta-wide{
    background: $primary;

.label{
    letter-spacing:0.5px;
}
@media only screen and (min-width: 600px) {
  .max-width{
    max-width:1200px;
    margin:auto;
}

}

}
</style>
