<template lang="pug">
    .pricing-example
        h2.mb-4.text-center Wondering exactly how many credits a piece of text costs to proofread?
        p.text-center You can paste your text here, or try our sample text.

        v-textarea(ref="textarea", placeholder="Insert your text here or try our example text" solo color="primary" hide-details v-model="textForSubmission")
        TextSlider(@change="handleSliderChange")
        .text-center.mx-auto
          OptionPremiumCheck
        .credit-count.text-center.display-1.font-weight-bold(:class="{'primary--text': !costsTooMuch, 'red--text': costsTooMuch}") {{calcWordLength(textForSubmission)}} words cost {{calcCreditCost({text: textForSubmission, returnWithLabel: true})}}

</template>

<script>
import TextSlider from '@/components/Static/TextSlider'
import OptionPremiumCheck from '@/components/Incoming/Submit/OptionPremiumCheck.vue'
import { get } from 'vuex-pathify'
export default {
  name: 'PricingExample',
  components: { TextSlider, OptionPremiumCheck },
  data() {
    return {
      textForSubmission: ''
    }
  },
  computed: {
    ...get({
      calcWordLength: 'transactions/calcWordLength',
      calcCreditCost: 'transactions/calcCreditCost'
    }),
    costsTooMuch() {
      return this.calcCreditCost({ text: this.textForSubmission }) === -1
    }
  },
  methods: {
    scrolldownTextArea() {
      const ref = this.$refs.textarea
      const elem = ref.$el
      const textArea = elem.querySelector('textarea')
      textArea.scrollTop = textArea.scrollHeight
    },
    handleSliderChange(e) {
      this.textForSubmission = e.trim()

      this.$nextTick(() => {
        this.scrolldownTextArea()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.credit-count {
  cursor: default;
}
</style>
