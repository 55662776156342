<template lang="pug">
    .timing(ref="feature")
        v-row(align="center")
            v-col(cols="12" md="6")
                v-img(src="@/assets/waiting.svg" min-width="200px" :min-height="$vuetify.breakpoint.mdAndUp ? '400px' : '100px'" max-width="400px" contain).mx-auto
            v-col(cols="12" md="6")
                .content(v-intersect.once="{handler: onIntersect, options: {threshold: [1]}}")
                    h2 {{text.title}}
                    p.font-weight-bold {{text.subtitle}}
                    p(v-for="paragraph in text.paragraphs", v-html="paragraph", :key="paragraph")
</template>

<script>
import { get } from 'vuex-pathify'
import { annotate } from 'rough-notation'
export default {
  name: 'Timing',
  data() {
    return {
      textVersions: {
        en: {
          title: "Don't keep your customers waiting: perfect native English in less than 1 hour.",
          subtitle: '',
          paragraphs: ['When a message is time-sensitive, you can submit it to us as an <b>"express check"</b> message. We\'ll take care of it immediately, <span class="highlight">within 60 minutes</span>, but usually faster.']
        },
        ko: {
          title: '당신의 고객을 기다리게 하지 마세요',
          subtitle: '60분 이내에 경험 할 수 있는 완벽한 원어민 영어',
          paragraphs: ['빠른 시간내에 메시지를 보내야 할 경우 <b>"익스프레스 체크"</b> 로 제출할 수 있습니다.', '보통 더 빠르지만 60분 이내에 즉시 처리해 드릴 수 있습니다.']
        },
        ru: {
          title: 'Не заставляйте Ваших клиентов ждать: идеальный английский носителя за 60 минут.',
          subtitle: '',
          paragraphs: ['Когда необходим быстрый ответ, услуга <b class="keep-together">"экспресс-проверки"</b> поможет Вам решить проблему.', 'Мы позаботимся об этом в течении часа.']
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) { this.doAnimation() }
    },
    doAnimation() {
      document.fonts.ready.then(() => {
        const ref = this.$refs.feature
        if (ref) {
          const e = ref.querySelector('.highlight')
          if (e) {
            const annotation = annotate(e, { type: 'highlight', multiline: true })
            setTimeout(() => {
              e.style.color = 'white'
            }, 200)

            annotation.show()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
