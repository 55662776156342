<template lang="pug">
    .services
        v-row(dense align="start")
            v-col(cols="6" md="3", v-for="item in text.items", :key="item.text").text-center
                v-img(:src="item.image" width="64px", height="64px").mx-auto.mb-4
                .text.mb-6.mb-md-0 {{item.text}}
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'Services',
  data() {
    return {
      textVersions: {
        en: {
          items: [
            { image: require('@/assets/service-email.svg'), text: 'Check an Email' },
            { image: require('@/assets/service-article.svg'), text: 'Check a web page' },
            { image: require('@/assets/service-socialmedia.svg'), text: 'Check a Social Media Post' },
            { image: require('@/assets/service-salesletter.svg'), text: 'Check a Sales Letter' }
          ]
        },
        ru: {
          items: [
            { image: require('@/assets/service-email.svg'), text: 'Проверьте электронную почту' },
            { image: require('@/assets/service-article.svg'), text: 'Проверьте веб-страницу' },
            { image: require('@/assets/service-socialmedia.svg'), text: 'Проверьте пост для социальной сети' },
            { image: require('@/assets/service-salesletter.svg'), text: 'Проверьте коммерческое предложение' }
          ]
        },
        ko: {
          items: [
            { image: require('@/assets/service-email.svg'), text: '이메일 확인 ✔️' },
            { image: require('@/assets/service-article.svg'), text: '홈페이지 확인 ✔️' },
            { image: require('@/assets/service-socialmedia.svg'), text: '게시물 확인 ✔️' },
            { image: require('@/assets/service-salesletter.svg'), text: '판매 편지 확인 ✔️' }
          ]
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  }
}
</script>
