<template lang="pug">
    .home-page
      .max-width.px-5.px-md-0
        MastheadNav

        v-container.mt-0.pt-0
          Masthead
          v-divider.my-12
          Services.mt-12.my-md-12
          //- v-divider.my-12

          Feature(v-for="feature, featureIdx in text.features", :key="feature.title", :label="feature.label", :title="feature.title", :paragraphs="feature.paragraphs", :image="feature.image", :alt="feature.alt", :wide="feature.wide")
          Timing

      CtaWide(:title="text.cta.title", :label="text.cta.label", :button="text.cta.button")
      .max-width.px-5.px-md-0
        HowItWorks.mb-12
        Security.mb-12
        Pricing.mb-12
        FinalCta.mb-12
      Footer
      SubFooter
</template>

<script>
import { get, call } from 'vuex-pathify'
import MastheadNav from '@/components/Static/MastheadNav'
import Masthead from '@/components/Static/Masthead'
import Services from '@/components/Static/Services'
import Feature from '@/components/Static/Feature'
import Timing from '@/components/Static/Timing'
import CtaWide from '@/components/Static/CtaWide'
import HowItWorks from '@/components/Static/HowItWorks'
import Security from '@/components/Static/Security'
import Pricing from '@/components/Static/Pricing'
import FinalCta from '@/components/Static/FinalCta'
import Footer from '@/components/Static/Footer'
import SubFooter from '@/components/Static/SubFooter'
export default {
  name: 'HomePage',
  components: {
    MastheadNav,
    Masthead,
    Services,
    Feature,
    Timing,
    CtaWide,
    HowItWorks,
    Security,
    Pricing,
    FinalCta,
    Footer,
    SubFooter
  },
  title: 'PROIT English',
  created() {
  },
  data() {
    return {
      textVersions: {
        en: {
          cta: {
            title: 'You can get started for free, with no credit card',
            label: 'We offer a generous demo tier',
            button: { text: 'Start Here' }
          },
          features: [
            {
              title: 'Write natural sales messages that inspire confidence',
              label: 'For your sales team',
              image: '/pr_flatline.svg',
              paragraphs: [
                "This is not a document translation service or a copywriting service. <span class='highlight'>We're native English editors</span> and we respond fast.",
                "Submit to us a quick email or document you're about to send, and we'll help you make it sound correct and professional."
              ]
            },
            {
              title:
            'Avoid confusion that slows down your work and costs you money',
              label: 'For your project team',
              image: '/decision_flatline.svg',
              paragraphs: [
                'If you send and receive confusing messages, you can spend weeks or months quietly <span class="font-weight-bold">going in circles.</span>',
                'We help your sales & support teams send messages that contain precisely-worded questions and give precisely-worded answers.'
              ],
              alt: true
            },
            {
              title:
            'Upgrade your team with daily, permanent access to a native-speaking English editor',
              label: 'For your top people',
              image: '/entrepreneurs_flatline.svg',
              paragraphs: [
                "Your top performers are already studying English, either through your company's English teacher, or by paying for lessons on their own.",
                'Provide your team leads and key people with the upgrade of a native English editor: someone they can call on for any question, any time.'
              ],
              alt: true,
              wide: true
            }
          ]
        },
        ru: {
          cta: {
            title: 'Вы можете начать работу БЕСПЛАТНО, без ввода данных банковской карты.',
            label: 'Мы предлагаем щедрый стартовый тариф!',
            button: { text: 'Старт' }
          },
          features: [
            {
              title: 'Пишите грамотные рекламные сообщения, которые внушают доверие',
              label: 'Для вашего отдела продаж',
              image: '/pr_flatline.svg',
              paragraphs: [
                'Это не сервис перевода документов или копирайтинг.<span class="highlight">Мы - носители английского</span> и быстро отвечаем на ваши запросы.',
                'Предоставьте нам свое электронное письмо или документ, который вы собираетесь отправить, и мы поможем грамотно и профессионально оформить его.'
              ]
            },
            {
              title: 'Избегайте путаницы, которая замедляет вашу работу и тратит ваши деньги',
              label: 'Для вашей команды',
              image: '/decision_flatline.svg',
              paragraphs: [
                'Если вы отправляете и получаете двусмысленные и запутанные письма, вы можете потратить недели или месяцы, <b>просто двигаясь по кругу. </b>',
                'Мы поможем вам отправить максимально точные и корректные сообщения, деловые предложения и письма.'
              ],
              alt: true
            },
            {
              title:
            'Улучшите свою команду с постоянным доступом к редактору-носителю английского',
              label: 'Для ваших лучших специалистов',
              image: '/entrepreneurs_flatline.svg',
              paragraphs: [
                'Предоставьте вашим сотрудникам возможность улучшить уровень их коммуникации с помощью редактора-носителя английского языка, к которому они могут обратиться в любое время.'
              ],
              alt: true,
              wide: true
            }
          ]
        },
        ko: {
          cta: {
            title: '신용 카드 없이 무료로 시작할 수 있습니다',
            label: '넉넉하게 데모 티어를 제공합니다.',
            button: { text: '시작하기' }
          },

          features: [
            {
              title:
            '자신있게 자연스러운 영업용 메시지를 쓸 수 있도록 도와 드립니다',
              label: '영업팀용',
              image: '/pr_flatline.svg',
              paragraphs: [
                "저희는 문서 번역이나 광고문안 작성 서비스가 아닙니다. 저희는 즉각적으로 대응하는 <span class='highlight'>영어 원어민 편집 서비스 입니다</span>. ",
                '보내려는 메일이나 문서를 제출해 주시면 정확하고 전문적으로 도와드립니다.'
              ]
            },
            {
              title: '업무 속도를 늦추고 비용을 낭비하는 혼동을 방지합니다.',
              label: '프로젝트 팀용',
              image: '/decision_flatline.svg',
              paragraphs: [
                '혼란스러운 메시지를 주고 받느라 몇 주 혹은 몇 달을 생산성 없이<br> 보낼 수 있습니다.',
                '귀사의 영업 지원팀이 정확한 문장으로 메시지를<br> 보낼 수 있게 도와줍니다.'
              ],
              alt: true
            },
            {
              title:
            '원어민 영어 편집 서비스를 매일 사용할 수 있도록 고객님의 팀을 업그레이드 하세요',
              label: '핵심인원용',
              image: '/entrepreneurs_flatline.svg',
              paragraphs: [
                '고객님의 핵심 인원들은 이미 사내 영어강사를 통해서나 스스로 수업료를 지불하면서 까지 영어 공부를 하고 있습니다.',
                '언제든지 연락하여 문의 할 수 있는 원어민 에디터 업그레이드 서비스를 팀 리더와 주요 인원들에게 제공해 보세요.'
              ],
              alt: true,
              wide: true
            }
          ]
        }
      }

    }
  },
  mounted() {
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  },
  methods: {
    ...call({ updateSystemLanguage: 'user/updateSystemLanguage' })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.home-page {
  .max-width {
    max-width: 1200px;
  }
}
</style>
