<template lang="pug">
    .price-block
        .name.text-uppercase.text-center.mb-1 {{name}}
        .card.pa-2.pa-md-5.text-center.d-flex.flex-column.justify-space-between.p-relative
            .ribbon.savings.text-uppercase(:class="{small: lang && lang === 'ru'}") {{savings}}
            .price.mt-5
                .amount.mb-8 {{amount}}
                .d-flex.justify-center.align-end.my-5
                    .currency.line-height-0.mr-1(v-if="dollars && dollars > 0") $
                    .price-in-dollars.line-height-0 {{dollars && dollars > 0 ? dollars : text.free}}
                    .price-in-cents(v-if="cents").line-height-0 .{{cents}}
                .units(v-if="dollars && dollars > 0") {{text.perCredit}}
            .subheader(v-if="subheader" v-html="subheader").mt-12
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'PriceBlock',
  props: {
    name: { type: String, default: null },
    amount: { type: String, default: null },
    priceInDollars: { type: String, default: null },
    subheader: { type: String, default: null },
    savings: { type: String, default: null }
  },
  data() {
    return {
      textVersions: {
        en: { perCredit: 'per credit', free: 'FREE' },
        ko: { perCredit: '크레딧 당', free: '무료' },
        ru: { perCredit: 'за кредит', free: 'Бесплатно' }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    },
    dollars() {
      if (!this.priceInDollars || this.priceInDollars === 0) return 0

      return this.priceInDollars.split('.')[0]
    },
    cents() {
      if (!this.priceInDollars || this.priceInDollars === 0) return null

      return this.priceInDollars.split('.')[1]
    }
  }
}
</script>

<style lang="scss" scoped>
$green: #8ae687;

.price-block {
  .ribbon {
    background: $green;
    position: absolute;
    top: 1rem;
    right: -2rem;
    transform: rotate(45deg);
    padding-left: 2rem;
    padding-right: 2rem;
  }

.ribbon.small{
   top:2rem;
   padding-right:1.5rem;
}
  .savings {
    font-size: 0.8rem;
    font-weight:bold;
  }

  .savings.small {
    font-size: 0.7rem;
    font-weight:bold;
  }
  .card {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 10px 10px 25px 3px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .name {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.63);
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }

  .amount,
  .units,
  .subheader {
    font-size: 0.9rem;
  }

  .price-in-dollars {
    font-size: 2rem;
  }

  .line-height-0{
      line-height:0;
  }

  @media only screen and (min-width: 600px) {
  .card{height:250px;}
}

}
</style>
