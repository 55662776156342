<template lang="pug">
    .how-it-works#how-it-works(ref="howItWorks")
        h2.text-center.mb-8 {{text.title}}

        v-row.mb-12
          v-col(cols="12" md="4")
            .label {{text.steps[0].label}}
            h3.mb-3 {{text.steps[0].title}}
            p(v-for="paragraph in text.steps[0].paragraphs", :key="paragraph" v-html="paragraph")
          v-col(cols="12" md="8")
            v-img(lazy-src="@/assets/compose_example.jpg", src="@/assets/compose_example.png" :min-width="$vuetify.breakpoint.mdAndUp ? '700px' : '300px'", :max-width="$vuetify.breakpoint.mdAndUp ? '100%' : '100vw'" contain).example

        v-row.mt-12.flex-column-reverse.flex-md-row
          v-col(cols="12" md="9")
            LazyVideo(v-if="$vuetify.breakpoint.smAndDown", src="videos/screencast.mp4" poster="videos/screencast_poster.jpg" ).example
            LazyVideoAsGIF(v-else src="videos/screencast.webm" poster="videos/screencast_poster.jpg" ).example
          v-col(cols="12" md="3")
            .label {{text.steps[1].label}}
            h3.mb-3 {{text.steps[1].title}}
            p(v-for="paragraph in text.steps[1].paragraphs", :key="paragraph" v-html="paragraph")

</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'HowItWorks',
  data() {
    return {
      textVersions: {
        en: {
          title: 'How it works',
          steps: [
            {
              label: 'Step 1 of 2',
              title: 'Submit your text',
              paragraphs: [
                "Are you working on an important email? About to send a message that will be seen by your customer's CEO?",
                "Our native English Editor can review your text first to make sure it's clear and concise, with the professional or casual tone you intended."
              ]
            },
            {
              label: 'Step 2 of 2',
              title: 'Review our feedback',
              paragraphs: [
                "We don't only check grammar: we're editors. We understand the technical and professional content of your message.",
                'We tell you where your text is confusing or where it might sound unprofessional or rude.'
              ]
            }
          ]
        },
        ru: {
          title: 'Как это работает',
          steps: [
            {
              label: 'Шаг 1 из 2',
              title: 'Отправьте свой текст',
              paragraphs: [
                'Вы работаете над важным письмом? Собираетесь отправить сообщение, которое увидит генеральный директор вашего клиента?',
                'Наш редактор-носитель языка может сначала просмотреть ваш текст, чтобы убедиться, что он ясен и лаконичен, с профессиональным или неформальным тоном, который вы намеревались использовать.'
              ]
            },
            {
              label: 'Шаг 2 из 2',
              title: 'Ознакомьтесь с нашим ответом',
              paragraphs: [
                'Мы не только проверяем грамматику: мы редакторы. Мы понимаем техническое и профессиональное содержание вашего сообщения',
                'Мы говорим вам, где ваш текст запутанный или где он может звучать непрофессионально или грубо.'
              ]
            }
          ]
        },
        ko: {
          title: '사용방법',
          steps: [
            {
              label: '2단계 중 1단계',
              title: '텍스트 제출',
              paragraphs: [
                '중요한 이메일을 쓰고 계십니까? 고객의 CEO가 볼 수 있는 메시지를 보내려고 하십니까?',
                '원어민 영어 편집자는 고객님이 원하는 전문적이거나 일상적인 어조로 먼저 명확하고 간결한 텍스트인지 검토 할 수 있습니다.'
              ]
            },
            {
              label: '2단계 중 2단계',
              title: '피드백을 검토하십시오',
              paragraphs: [
                '저희는 문법만 확인하지 않고 편집 일을 합니다. 저희는 고객 메시지의 기술적이고 전문적 내용까지 이해합니다.',
                '혼동되는 부분이나 전문적이지 않고 무례하게 들릴 수 있는 텍스트 부분을 알려드립니다.'
              ]
            }
          ]
        }
      }
    }
  },
  mounted() {},
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.how-it-works {
  .example {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    max-width: 100%;
  }

  .label {
    color: grey;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
  }
}
</style>
