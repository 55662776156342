<template lang="pug">
    .security.pa-4.pa-md-12
        .d-flex.justify-center.align-center.mb-5
            h2.text-center {{text.title}}
        v-row
            v-col(cols="12" md="5")
                v-img(src="@/assets/security.svg" :min-width="$vuetify.breakpoint.mdAndUp ? '300px' : '80vw'", min-height="300px", :max-width="$vuetify.breakpoint.mdAndUp ? '100%' : '90vw'" contain).mb-3

                .d-flex.align-start
                    v-icon.ma-1(color="black") mdi-information
                    p.font-weight-bold {{text.features[0]}}
                .d-flex.align-start
                    v-icon.ma-1(color="black") mdi-lock
                    p.font-weight-bold {{text.features[1]}}
            v-col(cols="12" md="7")
                p.font-weight-bold {{text.bullets.title}}
                ol
                    li(v-for="item in text.bullets.items", :key="item" v-html="item")
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'Security',
  data() {
    return {
      textVersions: {
        en: {
          title: 'Private + Secure',
          features: ['We work with you under NDA.', 'When you submit text, our algorithms redact it for privacy first.'],
          bullets: {
            title: 'Multi-layer information protection',
            items: ['We agree to sign a detailed non-disclosure agreement that covers all of our work with you.', 'When submitting text to us, our algorithms automatically filter out email addresses, phone numbers, credit card numbers, and currency amounts, before your text ever hits our database.', 'We store your user info in only 1 place in our database, so if ever requested it is trivial for us to <em>permanently hard-delete</em> your PII, and all of the content of any submissions you have ever made.']
          }
        },
        ru: {
          title: 'Конфиденциально и безопасно',
          features: ['Мы работаем с вами в рамках NDA.', 'Когда вы отправляете текст, наши алгоритмы сначала редактируют его для обеспечения конфиденциальности.'],
          bullets: {
            title: 'Многоуровневая защита информации',
            items: ['Мы согласны подписать подробное соглашение о неразглашении (NDA), которое охватывает всю нашу работу с вами.', 'При отправке нам письма наши алгоритмы автоматически отфильтровывают адреса электронных почт, номера телефонов, номера кредитных карт и суммы сделок еще перед тем, как ваш текст попадет в нашу базу данных.', 'Мы храним вашу конфиденциальную информацию только в 1 месте нашей базы данных, поэтому, если когда-либо потребуется, для нас будет простой задачей навсегда удалить ваш PII и все содержимое любых материалов, которое вы когда либо делали.']
          }
        },
        ko: {
          title: '개인 + 보안',
          features: ['저희는 NDA 하에서 당신과 함께 일합니다.', '텍스트를 제출하시면 알고리즘이 개인 정보 보호 관련 텍스트를 먼저 삭제합니다.'],
          bullets: {
            title: '다중 정보 보호',
            items: ['저희는 귀사와 함께하는 모든 상세 업무 내용에 대한 비공개 계약서 서명에 동의합니다.', '알고리즘은 텍스트가 데이터베이스에 저장되기 전에 이메일 주소, 전화 번호, 신용 카드 번호, 통화량을 자동 필터링합니다.', '저희는 사용자 정보를 데이터베이스의 한 장소에만 저장하므로, 귀하의 고유식별정보와 제출하신 모든 내용을 영구하게 삭제하는 것이 간단합니다.']
          }
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style scoped lang="scss">
.security {
background: #fafafa;
border-radius:20px;
}
</style>
